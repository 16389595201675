<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Org" vid="org_id" rules="required|min_value:1">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="org_id"
                slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                  {{ $t('globalTrans.organization')}} <span class="text-danger">*</span>
                  </template>
                <b-form-select
                  plain
                  v-model="form.details.org_id"
                  disabled
                  :options="orgList"
                  id="org_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="office_type_id"
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                {{ $t('userManagement.office_type')}} <span class="text-danger">*</span>
                </template>
                <b-form-select
                  plain
                  v-model="form.details.office_type_id"
                  :options="officeTypeList"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="office_id"
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  {{ $t('userManagement.office')}} <span class="text-danger">*</span>
                </template>
                <b-form-select
                  plain
                  v-model="form.details.office_id"
                  :options="officeList"
                  id="office_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Designation" vid="designation_id" rules="required|min_value:1">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="designation_id"
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                {{ $t('globalTrans.designation')}} <span class="text-danger">*</span>
                </template>
                <b-form-select
                  plain
                  v-model="form.details.designation_id"
                  :options="designationList"
                  @change="getUser"
                  id="designation_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Officer's Name" vid="receiver_id" rules="required|min_value:1">
              <b-form-group
                label-for="receiver_id"
                label-cols-sm="4"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{ $t('tradeTariffApplication.officer_name') }} <span class="text-danger">*</span>
              </template>
              <b-form-select
                plain
                v-model="form.details.user_id"
                :options="userList"
                id="receiver_id"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{ userLoading ? 'Loading..' : $t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Forward Note" vid="comment_id" rules="required|min_value:1">
              <b-form-group
                label-for="comment_id"
                label-cols-sm="4"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{ $t('tradeTariffApplication.forward_note') }} <span class="text-danger">*</span>
              </template>
              <b-form-select
                plain
                v-model="form.details.comment_id"
                :options="commentList"
                id="comment_id"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{ userLoading ? 'Loading..' : $t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="12" sm="12">
              <ValidationProvider name="Note" vid="note">
                <b-form-group
                  class="row"
                  label-cols-sm="2"
                  label-for="note"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  {{ $t('globalTrans.note')}}
                  </template>
                  <b-form-textarea
                    v-model="form.details.note"
                    id="accused_person_address_en"
                    :state="errors[0] ? false : (valid ? true : null)"
                  >
                  </b-form-textarea>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
        </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { authServiceBaseUrl, tradeTariffServiceBaseUrl } from '@/config/api_config'
import { applicationStore, applicationUpdate, userListByDesignationApi } from '../../api/routes'
export default {
  name: 'Form',
  props: ['id', 'secretQuestionList'],
  data () {
    return {
      tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.forward') : this.$t('globalTrans.forward'),
      errors: [],
      userList: [],
      userLoading: false,
      form: {
        details: {
          user_id: 0,
          designation_id: 0,
          org_id: 4,
          office_type_id: 0,
          office_id: 0,
          application_status: 2,
          comment_id: 0,
          note: ''
        }
      },
      officeTypeList: [],
      officeList: [],
      designationList: []
    }
  },
  created () {
    this.secretQList = this.secretQuestionList
    this.officeTypeList = this.getOfficeTypeList(4)
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    orgList: function () {
      return this.$store.state.CommonService.commonObj.orgProfileList
    },
    commentList: function () {
      const objectData = this.$store.state.TradeTariffService.commonObj.commentList.filter(item => item.status === 1 && item.type_id === 1)
      return objectData.map((obj, key) => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
          } else {
              return { value: obj.value, text: obj.text_en }
          }
        })
    },
    passwordRules: function () {
      return this.id ? '' : 'required|min:6'
    },
    passwordConfirmRules: function () {
      return this.id ? '' : 'required|min:6'
    }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.localizeUserList(newVal)
      }
    },
    'form.details.org_id': function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.officeTypeList = this.getOfficeTypeList(newVal)
      }
    },
    'form.details.office_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.officeList = this.getOfficeList(newVal)
      }
    },
    'form.details.office_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.designationList = this.getDesignationList(newVal)
      }
    }
  },
  methods: {
    getUser () {
      this.getUserList(this.form.details.designation_id)
    },
    getOfficeTypeList (orgId) {
      return this.$store.state.CommonService.commonObj.officeTypeList.filter(offType => offType.org_id === orgId)
    },
    getOfficeList (officeTypeId) {
      return this.$store.state.CommonService.commonObj.officeList.filter(office => office.office_type_id === officeTypeId)
    },
    getDesignationList (officeId) {
      const objectData = this.$store.state.CommonService.commonObj.assignDesignationList.filter(desgn => desgn.office_id === officeId && desgn.office_type_id === this.form.details.office_type_id && desgn.org_id === this.form.details.org_id)
       return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    getUserList (designationId) {
      this.userLoading = true
      RestApi.getData(authServiceBaseUrl, `${userListByDesignationApi}/${designationId}`).then(response => {
        // const userList = response.filter(obj => obj.value !== this.$store.state.Auth.authUser.user_id).map((obj, index) => {
        const userList = response.map((obj, index) => {
          return Object.assign({}, obj, {
            value: obj.value,
            text: this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
          })
        })
        this.userList = userList.filter(item => item.value !== parseInt(this.$store.state.Auth.authUser.user_id))
        this.userLoading = false
        return userList
      })
    },
    handlePhoto (e) {
      const selectedPhoto = e.target.files[0]
      const reader = new FileReader()
      reader.onload = (event) => {
        this.form.details.photo = event.target.result
        this.form.details.update_photo = 1
      }
      reader.readAsDataURL(selectedPhoto)
    },
    localizeUserList (locale) {
      const tmp = this.userList.map(item => {
        return Object.assign({}, item, {
          text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn
        })
      })
      this.userList = tmp
    },
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      const formData = this.form
      if (this.id) {
        result = await RestApi.putData(tradeTariffServiceBaseUrl, `${applicationUpdate}/${this.id}`, formData)
      } else {
        result = await RestApi.postData(tradeTariffServiceBaseUrl, applicationStore, formData)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('mutateCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>

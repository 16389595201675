export const userListByDesignationApi = 'user/users-by-designation-id'

// application Info Routes......
const application = '/application-management/application/'
export const applicationList = application + 'list'
export const applicationUpdate = application + 'forward'
export const applicationToggle = application + 'toggle-status'
export const applicationSendToAssignList = application + 'assign'
export const applicationPdf = application + 'pdf'
export const sendToNothiStore = application + 'send-to-nothi'

const approveApplication = '/application-management/approve-application/'
export const approveApplicationList = approveApplication + 'list'
export const approveApplicationStore = approveApplication + 'store'
export const approveApplicationUpdate = approveApplication + 'update'
export const approveApplicationToggle = approveApplication + 'toggle-status'
export const applicationSendToapproveList = approveApplication + 'approve-application-status'

const rejectApplication = '/application-management/rejected-application/'
export const rejectedApplicationList = rejectApplication + 'list'
export const applicationSendToRejectList = rejectApplication + 'reject-application-status'

const assignedApplication = '/application-management/assigned-application/'
export const assignedApplicationList = assignedApplication + 'list'
export const assignedApplicationStore = assignedApplication + 'store'
export const assignedApplicationUpdate = assignedApplication + 'update'
export const assignedApplicationToggle = assignedApplication + 'toggle-status'

const receivedApplication = '/application-management/received-application/'
export const receivedApplicationList = receivedApplication + 'list'
export const receivedApplicationStore = receivedApplication + 'store'
export const receivedApplicationUpdate = receivedApplication + 'update'
export const receivedApplicationToggle = receivedApplication + 'toggle-status'

export const dutyCalculator = '/application-management/received-application/list'
export const FeedbackAndRatingList = '/application-management/feedback-rating/list'

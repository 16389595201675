<template>
    <b-overlay :show="unitLoading">
        <b-container fluid>
            <b-row class="text-right mt-2 mb-3">
              <b-col>
                <b-button @click="pdfExport()" class="btn btn-success btn-md">
                  <i class="ri-file-pdf-line"></i>  {{  $t('globalTrans.pdf') }}
                </b-button>
              </b-col>
            </b-row>
            <template>
                <b-row>
                    <b-col md="6"><b>{{$t('globalTrans.applicant_name')}}        </b>: {{ currentLocale === 'bn' ? userDetails[0].name_bn : userDetails[0].name }}</b-col>
                    <b-col md="6"><b>{{$t('globalTrans.application_id')}}         </b> : {{ items.auto_id }} </b-col>
                    <b-col md="6"><b>{{$t('globalTrans.email')}}                 </b>: {{ userDetails[0].email }}</b-col>
                    <b-col md="6"><b>{{$t('globalTrans.fiscal_year')}}            </b> : {{ getFiscalYear(items.fiscal_year_id) }}</b-col>
                    <b-col md="6"><b>{{$t('globalTrans.application_type')}}      </b>: {{ getApplicationType(items.application_type_id) }} </b-col>
                    <b-col md="6" v-if="userDetails[0].mobile"><b>{{$t('globalTrans.mobile')}}                </b>: {{currentLocale == 'bn'? userDetails[0].mobile.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d]) : userDetails[0].mobile}}</b-col>
                    <b-col md="6"><b>{{$t('globalTrans.nid') }}                  </b>: {{ $n(userDetails[0].nid_no, { useGrouping: false }) }}</b-col>
                    <b-col md="6"><b>{{$t('globalTrans.father_name')}}           </b>: {{ currentLocale === 'bn' ? userDetails[0].father_name_bn : userDetails[0].father_name_en }}</b-col>
                    <b-col md="6"><b>{{$t('globalTrans.gender')}}                </b>: {{ userDetails[0].gender ? getGenderName(userDetails[0].gender) : '' }}</b-col>
                    <b-col md="6"><b>{{$t('globalTrans.mother_name')}}           </b>: {{ currentLocale === 'bn' ? userDetails[0].mother_name_bn : userDetails[0].mother_name_en }}</b-col>
                    <b-col md="6"><b>{{$t('globalTrans.dob')}}                   </b>: {{ userDetails[0].dob | dateFormat }}</b-col>
                    <b-col md="6"><b>{{$t('externalUser.birth_place')}}          </b>: {{ currentLocale === 'bn' ? userDetails[0].birth_place_bn : userDetails[0].birth_place_en }}</b-col>
                    <b-col md="6"><b>{{$t('globalTrans.nationality')}}           </b>: {{ currentLocale === 'bn' ? userDetails[0].nationality_bn : userDetails[0].nationality_en }}</b-col>
                    <b-col md="6"><b>{{$t('globalTrans.occupation')}}            </b>: {{ currentLocale === 'bn' ? userDetails[0].occupation_bn : userDetails[0].occupation_en }}</b-col>
                    <b-col md="6"><b>{{$t('tradeTariffApp.company_name')}}       </b>: {{ currentLocale === 'en'? items.company_name_en : items.company_name_bn}} </b-col>
                    <b-col md="6"><b>{{$t('externalTradeTraiff.application_subject')}}            </b> : {{ currentLocale === 'bn' ? items.application_subject_bn : items.application_subject_en }}</b-col>
                    <b-col md="6"><b>{{$t('eBizConfig.description')}}            </b>: {{ currentLocale === 'en'? items.description_en : items.description_bn}} </b-col>
                    <b-col md="6"><b>{{$t('externalTradeTraiff.bin_no')}}                </b>: {{ items.bin_no }} </b-col>
                    <b-col md="6"><b> {{ $t('externalTradeTraiff.license_no') }}            </b>: {{ items.license_no }} </b-col>
                    <!--<b-col md="6"><b>{{$t('tradeTariffApp.application_status')}} </b>: {{ statusText(items.application_status) }} </b-col>-->
                    <hr>
                    <b-col md="4"><b>{{$t('globalTrans.main_attachment')}}</b>:
                        <span v-if="items.attachment_main !== null"><a target="_blank" class="btn btn-primary mr-2"
                                :href="tradeTariffServiceBaseUrl+'download-attachment?file='+items.attachment_main"
                                title="Attachment Main"><i class="ri-file-download-line"></i>&nbsp;</a></span>
                        <span v-else>N/A</span>
                    </b-col>
                    <b-col md="4"><b>{{$t('globalTrans.bin_attachment') }}</b>:
                        <span v-if="items.bin_attachment !== null"><a target="_blank" class="btn btn-primary mr-2"
                                :href="tradeTariffServiceBaseUrl+'download-attachment?file='+items.bin_attachment"
                                title="Bin Attachment"><i class="ri-file-download-line"></i>&nbsp;</a></span>
                        <span v-else>N/A</span>
                    </b-col>
                    <b-col md="4"><b>{{$t('globalTrans.license_attachment')}}</b>:
                        <span v-if="items.license_attachment !== null"><a target="_blank" class="btn btn-primary mr-2"
                                :href="tradeTariffServiceBaseUrl+'download-attachment?file='+items.license_attachment"
                                title="Bin Attachment"><i class="ri-file-download-line"></i>&nbsp;</a></span>
                        <span v-else>N/A</span>
                    </b-col>
                    <hr>
                        <table class="table table-sm table-bordered section-tree-view-table">
                            <thead>
                                <tr>
                                    <th>{{ $t('teaGardenPanel.attachment_name') }}</th>
                                    <th>{{ $t('teaGardenPanel.attachment') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <slot v-for="(item, index) in items.add_more_files">
                                    <tr :key="index">
                                    <td>{{ currentLocale === 'bn' ? item.file_name_bn : item.file_name_en }}</td>
                                    <td>
                                        <a target="_blank" style="margin-left: 8px;" v-if="item.attachment" :href="tradeTariffServiceBaseUrl  + item.attachment" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                    </td>
                                    </tr>
                                </slot>
                            </tbody>
                        </table>
                        <hr>
                        <table class="table table-sm table-bordered">
                            <thead v-if="items.forward_attachments.length > 0">
                                <tr>
                                    <th>{{ $t('globalTrans.forward') }} {{ $t('globalTrans.note') }}</th>
                                </tr>
                            </thead>
                            <tbody v-if="items.forward_attachments">
                                <slot v-for="(item, index) in items.forward_attachments">
                                    <tr :key="index">
                                    <td>
                                        <b>{{index + 1}}. {{ $t('tradeTariffConfig.comment') }}: </b> {{ getCommentName(item.comment_id)}} <br> <b>{{ $t('globalTrans.note') }} {{$t('globalTrans.details')}} : </b> {{item.note}}
                                    </td>
                                    </tr>
                                </slot>
                            </tbody>
                        </table>
                        <hr>
                        <table class="table table-sm table-bordered">
                            <tbody v-if="items.assign_comment_id">
                                <tr>
                                    <th>{{ $t('tradeTariffApplication.assign') }} {{ $t('globalTrans.note') }}</th>
                                    <th>{{$t('globalTrans.attachment')}}</th>
                                </tr>
                                <tr>
                                    <td>
                                        <b>{{ $t('tradeTariffConfig.comment') }}: </b>
                                        {{ getCommentName(items.assign_comment_id)}} <br>
                                        <b>{{ $t('globalTrans.note') }} {{$t('globalTrans.details')}} : </b>
                                        {{items.note_assign}}
                                    </td>
                                    <td>
                                        <a target="_blank" style="margin-left: 8px;" v-if="items.attachment_assign" :href="tradeTariffServiceBaseUrl  + items.attachment_assign" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr>
                        <table class="table table-sm table-bordered" v-if="items.application_status === 4 || items.application_status === 5">
                            <tbody>
                                <tr>
                                    <th v-if="items.application_status === 4">{{ $t('tradeTariffApplication.approve') }} {{ $t('globalTrans.note') }}</th>
                                    <th v-if="items.application_status === 5" >{{ $t('globalTrans.archive') }} {{ $t('globalTrans.note') }}</th>
                                    <th>{{$t('globalTrans.attachment')}}</th>
                                </tr>
                                <tr>
                                    <td v-if="items.application_status === 5 || items.application_status === 4">
                                     <b>{{ $t('tradeTariffConfig.comment') }}: </b>
                                     <span v-if="items.application_status === 5 ">{{ getCommentName(items.archive_comment_id)}}</span>
                                     <span v-if="items.application_status === 4 ">{{ getCommentName(items.approve_comment_id)}}</span>
                                         <br>
                                        <b>{{ $t('globalTrans.note') }} {{$t('globalTrans.details')}} : </b>
                                        {{items.note_approve}}
                                    </td>
                                    <td>
                                        <a target="_blank" style="margin-left: 8px;" v-if="items.attachment_approve" :href="tradeTariffServiceBaseUrl  + items.attachment_approve" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                        <a target="_blank" style="margin-left: 8px;" v-if="items.attachment_reject" :href="tradeTariffServiceBaseUrl  + items.attachment_reject" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                </b-row>
            </template>
        </b-container>
    </b-overlay>
</template>
<script>
import AddressHelper from '@/utils/area-type-address'
import RestApi, { authServiceBaseUrl, tradeTariffServiceBaseUrl } from '@/config/api_config'
import { applicationPdf } from '../../api/routes'
export default {
    props: ['items', 'userDetails'],
    components: {
    },
    created () {
            this.userDetails = this.userDetails.filter(user => user.user_id === parseInt(this.items.created_by))
    },
    mounted () {
    },
    data () {
        return {
            authServiceBaseUrl: authServiceBaseUrl,
            AddressHelper: AddressHelper,
            tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
            userList: [],
            user: '',
            unitLoading: false
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        },
        requestStatusList: function () {
            const list = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Pending' : 'অপেক্ষমাণ' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Processing' : 'প্রক্রিয়াধীন' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'Processing' : 'প্রক্রিয়াধীন' },
                { value: 4, text: this.$i18n.locale === 'en' ? 'Approved' : 'অনুমোদিত' },
                { value: 5, text: this.$i18n.locale === 'en' ? 'Agreement' : 'প্রত্যাখ্যাত' }
                ]
            return list
        },
        activeStatusList: function () {
            const list = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Active' : 'সক্রিয়' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Inactive' : 'নিষ্ক্রিয়' }
                ]
            return list
        }
    },
    methods: {
         getCommentName (id) {
            const obj = this.$store.state.TradeTariffService.commonObj.commentList.find(item => item.value === parseInt(id))
            if (obj) {
                return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
            } else {
                return ''
            }
         },
        getFiscalYear (id) {
            const data = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === parseInt(id))
            if (typeof data !== 'undefined') {
                return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
            } else {
                return ''
            }
        },
        getApplicationType (val) {
            const Obj = this.$store.state.TradeTariffService.commonObj.applicationTypeList.find(el => el.value === val)
            if (Obj) {
                return this.$i18n.locale === 'bn' ? Obj.text_bn : Obj.text_en
            } else {
                return ''
            }
        },
        getDivisionName (Id) {
        const Obj = this.$store.state.CommonService.commonObj.divisionList.find(item => item.value === parseInt(Id))
        if (Obj) {
            return this.$i18n.locale === 'bn' ? Obj.text_bn : Obj.text_en
        } else {
            return ''
        }
            },
        statusText (sta) {
            const reqStatusObj = this.requestStatusList.find(tlaw => tlaw.value === parseInt(sta))
                return reqStatusObj.text
            },
        activeStatus (sta) {
            const reqStatusObj = this.activeStatusList.find(tlaw => tlaw.value === parseInt(sta))
                return reqStatusObj.text
            },
        getGenderName (genId) {
            const cateObj = this.$store.state.commonObj.genderList.find(item => item.value === genId)
            if (cateObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return cateObj.text_bn
                } else {
                    return cateObj.text_en
                    }
                }
        },
        async pdfExport () {
            this.unitLoading = true
            const params = Object.assign({}, { request_type: 'pdf', local: this.$i18n.locale, org_id: 7, created_by: this.items.created_by, data_id: this.items.id })
            const result = await RestApi.getPdfData(tradeTariffServiceBaseUrl, applicationPdf, params)
            var blob = new Blob([result], {
                type: 'application/pdf'
            })
            var url = window.URL.createObjectURL(blob)
            window.open(url).print()
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            this.unitLoading = false
        }
    }
}

</script>
